// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-btn {
  cursor: pointer;
  margin-left: 20px;
}

::ng-deep .mat-mdc-tab-body-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.1254901961);
}
::ng-deep .mat-mdc-tab.mdc-tab {
  border: 1px solid rgba(0, 0, 0, 0.1254901961);
}`, "",{"version":3,"sources":["webpack://./src/app/components/current-conditions/current-conditions.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;AACF;;AAGE;EACE,6CAAA;AAAJ;AAGE;EACE,6CAAA;AADJ","sourcesContent":[".close-btn {\n  cursor: pointer;\n  margin-left: 20px;\n}\n\n::ng-deep {\n  .mat-mdc-tab-body-wrapper {\n    border: 1px solid #00000020;\n  }\n\n  .mat-mdc-tab.mdc-tab {\n    border: 1px solid #00000020;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
