// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-header {
  margin-bottom: -1px;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}
.tabs-header .tabs-label-wrapper {
  position: relative;
  overflow: auto;
  white-space: nowrap;
}
.tabs-header .tab-label {
  flex-grow: 1;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1254901961);
  border-style: solid;
  padding: 15px 25px;
  cursor: pointer;
}
.tabs-header .tab-label.active {
  color: red;
  border-color: rgba(0, 0, 0, 0.5019607843);
  border-bottom-color: #ffffff;
}

.tabs-body {
  border: 1px solid rgba(0, 0, 0, 0.5019607843);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/tabs/tab-group/tab-group.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;AACF;AACE;EACE,kBAAA;EACA,cAAA;EACA,mBAAA;AACJ;AACE;EACE,YAAA;EACA,iBAAA;EACA,yCAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AACJ;AAEI;EACE,UAAA;EACA,yCAAA;EACA,4BAAA;AAAN;;AAIA;EACE,6CAAA;AADF","sourcesContent":[".tabs-header {\r\n  margin-bottom: -1px;\r\n  display: flex;\r\n  flex-grow: 1;\r\n  overflow: hidden;\r\n\r\n  .tabs-label-wrapper {\r\n    position: relative;\r\n    overflow: auto;\r\n    white-space: nowrap;\r\n  }\r\n  .tab-label {\r\n    flex-grow: 1;\r\n    border-width: 1px;\r\n    border-color: #00000020;\r\n    border-style: solid;\r\n    padding: 15px 25px;\r\n    cursor: pointer;\r\n\r\n\r\n    &.active {\r\n      color: red;\r\n      border-color: #00000080;\r\n      border-bottom-color: #ffffff;\r\n    }\r\n  }\r\n}\r\n.tabs-body {\r\n  border: 1px solid #00000080;\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
